import { FrontendEnvironment } from '@weavix/domain/src/frontend-environment/frontend-environment';
import { WeavixEnv } from './environment.model';
import { getTeamsAppId, isTeamsApp } from './environment.teams';

const location = window.location;
const frontendEnvironment = new FrontendEnvironment(location.hostname);
const domain = frontendEnvironment.domain;

export const environment: WeavixEnv = {
    production: frontendEnvironment.isProduction(),
    radioUrl: frontendEnvironment.getSiblingUrl('radio'),
    consoleUrl: frontendEnvironment.getSiblingUrl('console'),
    is360Api: frontendEnvironment.getSiblingUrl('api'),
    pubsubApi: frontendEnvironment.getSiblingUrl('mqtt').replace('https://', ''),
    pubsubPort: frontendEnvironment.getPubSubPort(),
    analyticsSource: location.host.startsWith('radio') || location.host.endsWith(':4201') ? 'Web-Radio' : 'Console',
    version: '5.23.59', // replaced during gitlab build - see set-ng-environment-version.sh
    commit: '4a4d8c1f3849d66ced0949218513f2bf7d23c899', // replaced during gitlab build - see set-ng-environment-version.sh
    releaseStage: frontendEnvironment.getReleaseStage(),
    enableRouteTracing: domain === 'localhost',
    teamsApp: isTeamsApp(),
    teamsAppId: getTeamsAppId(domain),
    radioApp: location.host.startsWith('radio') || location.host.endsWith(':4201'),
    consoleApp: location.host.startsWith('console') || location.host.endsWith(':4202'),
    supportUrl: 'https://help.weavix.com',
};
