
export enum TelemetryEvent {
    PttSent = 'PttSent',
    PttJitter = 'PttJitter',
    PttSkipped = 'PttSkipped',
    PttBuzzed = 'PttBuzzed',
    PttPressed = 'PttPressed',
    PttReceive = 'PttReceive',
    SpeechTranscriptionEvent = 'SpeechTranscriptionEvent',
    TextIdentificationEvent = 'TextIdentificationEvent',
    TextTranslationEvent = 'TextTranslationEvent',
    MqttPacket = 'MqttPacket',
    CombainLocationTelemetry = 'CombainLocationTelemetry',
    TrilaterationTelemetry = 'TrilaterationTelemetry',
    LocationData = 'LocationData',
    LocationHeartbeat = 'LocationHeartbeat',
    HeavyRequest = 'HeavyRequest',
    EvilUserRequest = 'EvilUserRequest',
    FacilityNetworksFoundEvent = 'FacilityNetworksFoundEvent',
    FacilityNetworksUpdateEvent = 'FacilityNetworksUpdateEvent',
    ServerBlocked = 'ServerBlocked',
    HermesBlocked = 'HermesBlocked',
    ServerBlockedAt = 'ServerBlockedAt',
    HermesBlockedAt = 'HermesBlockedAt',
    JavaBlockedAt = 'JavaBlockedAt',
    QueryLimitEvent = 'QueryLimitEvent',
    LocationManagerStart = 'LocationManagerStart',
    LocationManagerStop = 'LocationManagerStop',
    AppBackgrounded = 'AppBackgrounded',
    AppForegrounded = 'AppForegrounded',
    MissingMessages = 'MissingMessages',
    PoorNetwork = 'PoorNetwork',
    ObservedNetworks = 'ObservedNetworks',
    SiteSurvey = 'SiteSurvey',
    ClientJsBlocked = 'ClientJsBlocked',
    GpsStart = 'GpsStart',
    GpsStop = 'GpsStop',
    ActivityMonitor = 'ActivityMonitor',
    ResourcesUsed = 'ResourcesUsed',
    AmbientNoiseSample = 'AmbientNoiseSample',
    LoginTime = 'LoginTime',
    ChannelSelfCorrection = 'ChannelSelfCorrection',
    ZendeskReplyWebhook = 'ZendeskReplyWebhook',
    SupportTicketCreated = 'SupportTicketCreated',
    BannerDisplayed = 'BannerDisplayed',
    BannerClicked = 'BannerClicked',
    BannerSwipedAway = 'BannerSwipedAway',
    MusicStationSelected = 'MusicStationSelected',
    MusicInterrupted = 'MusicInterrupted',
    TeamsSubscription = 'TeamsSubscription',
    SyncQuery = 'SyncQuery',
    SyncPublish = 'SyncPublish',
    SyncStartup = 'SyncStartup',
    ConsolePageViewed = 'ConsolePageViewed',
    WebRadioPageViewed = 'WebRadioPageViewed',
    SyncDeduplicate = 'SyncDeduplicate',
    SyncEventMissing = 'SyncEventMissing',
    CacheStats = 'CacheStats',
    SiteSurveyReadingCreated = 'SiteSurveyReadingCreated',
    SiteSurveyObservationSubmitted = 'SiteSurveyObservationSubmitted',
    SiteSurveyWaltConnected = 'SiteSurveyWaltConnected',
    SiteSurveyWaltLost = 'SiteSurveyWaltLost',
    SiteSurveyWaltAbandoned = 'SiteSurveyWaltsAbandoned',
    SiteSurveyWaltObservationTaken = 'SiteSurveyWaltObservationTaken',
    StartingDiagnostics = 'StartingDiagnostics',
    BatterySessionPublish = 'BatterySessionPublish',
    BatterySessionStart = 'BatterySessionStart',
    QueuedAudioDidPlay = 'QueuedAudioDidPlay',
    BadDock = 'BadDock',
    AudioSessionPublish = 'AudioSessionPublish',
    PttTimeline = 'PttTimeline',
    WaltBetterWifiDetected = 'WaltBetterWifiDetected',
    WifiAccessPointChange = 'WifiAccessPointChange',
    LteTowerChange = 'LteTowerChange',
    ScreenAwakeTooLong = 'ScreenAwakeTooLong',
    ChannelFavorited = 'ChannelFavorited',
    ChannelUnfavorited = 'ChannelUnfavorited',
}
